import { AssetV2 } from 'types/asset.type';
import { Filters } from 'types/filter.type';
import { AssetListResponse, SortDirection, SortField } from '../types/asset-management.service.type';
import { AssetListState } from '../types/asset-management-store.type';

const getAssetListStateFromResponse = (
    response: AssetListResponse,
    previousAssetsList: AssetV2<unknown, unknown>[],
    shouldRefresh: boolean,
    params: {
        filters?: Filters;
        sortField?: SortField;
        sortDirection?: SortDirection;
    }
): Omit<AssetListState, 'assetFilters'> => {
    const { assets, nextPageToken, counts, totalAssetsCount, filteredAssetsCount } = response;
    return {
        assetsList: shouldRefresh ? assets : [...(previousAssetsList || []), ...assets],
        assetsNextPageToken: nextPageToken,
        assetsLoading: false,
        assetsCounts: counts,
        assetSortField: params.sortField,
        assetSortDirection: params.sortDirection,
        totalAssetsCount,
        filteredAssetsCount
    };
};

export { getAssetListStateFromResponse };
