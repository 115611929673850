import { AssetFieldOption } from '../types/asset-management-field.type';

// Add a visual indicator of its depth to the label of each option
const formatOptionsWithDepth = (options: AssetFieldOption[]): AssetFieldOption[] => {
    return options.map((option) => {
        const label = option.depth && option.depth > 0 ? `${'—'.repeat(option.depth || 0)} ${option.label}` : option.label;
        return {
            ...option,
            label
        };
    });
};

export { formatOptionsWithDepth };
