import { useRef, useState } from 'react';
import { AssetStatus, AssetV2 } from 'types/asset.type';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import AssetManagementService from '../services/asset-management.service';
import { FetchPayload } from '../types/asset-management.service.type';
import {
    findAssetInStore,
    getAssetFieldsWithOptions,
    getAssetFilterSetup,
    getCollectionsListState,
    getListState,
    getNestedCollectionPath,
    getRootModelFromStatus,
    getSubTypeItems,
    resetList,
    storeAssetListResponse,
    transformAssetParamsForRootKeys
} from '../utilities';
import { useAssetManagementConfigContext } from '../context/asset-management-config.context';
import { getListParams } from '../utilities/getListParams';
import { useAMDataDisplayStateContext } from '../context/asset-management-display-state.context';
import { COLLECTION_TREE_ROOT_ID } from '../constants';
import AssetManagementLastRequest from '../data/asset-management-last-request';

const useAMFetch = (): {
    error: string | null;
    fetchAssets: (refresh: boolean, status: AssetStatus, payload?: FetchPayload, requestId?: string) => Promise<void>;
    fetchAsset: (assetId: string, refresh?: boolean, requestId?: string) => Promise<AssetV2<unknown, unknown> | null>;
    fetchCollections: (refresh: boolean, payload?: FetchPayload, requestId?: string) => Promise<void>;
    fetchCollectionItems: (collectionId: string, refresh: boolean, payload?: FetchPayload, requestId?: string) => Promise<void>;
    fetchCollectionsTree: (collectionId?: string) => Promise<void>;
    refreshLists: (status: AssetStatus) => void;
} => {
    const [error, setError] = useState<string | null>(null);
    const { collectionsOpen, setCollectionsOpen } = useAMDataDisplayStateContext();
    const assetManagementConfig = useAssetManagementConfigContext();
    const { storeName, type, fullDataInList } = assetManagementConfig;
    const check = useRef(Math.floor(Math.random() * 101));

    // Fetch assets form the API.
    const fetchAssets = async (refresh: boolean, status: AssetStatus, payload?: FetchPayload, requestId?: string) => {
        setError(null);
        // If a request with the same id is allready in progress, we do not want to make another request.
        if (requestId && requestId === AssetManagementLastRequest.getId()) return;

        // If a new request is made with a requestId, we store the request id to make sure we only handle the last request.
        AssetManagementLastRequest.setId(requestId);

        const rootModel = getRootModelFromStatus(status);
        const currentState = getListState(storeName, status);
        const { stateList } = currentState;
        const shouldRefresh = Boolean(refresh || payload);

        if (shouldRefresh) {
            resetList(storeName, rootModel);
        } else {
            ComponentStoreHelpers.setModel(storeName, `${rootModel}assetsLoading`, true);
        }

        // Do not wait for the API to set the choosen filters.
        if (payload?.filters) ComponentStoreHelpers.setModel(storeName, `${rootModel}assetFilters`, payload.filters);

        const params = getListParams(type, currentState, status, shouldRefresh, payload);
        const transformedAssetsParams = transformAssetParamsForRootKeys(params);

        await AssetManagementService.getAssetBatch(transformedAssetsParams, { isVerbose: fullDataInList })
            .then((response) => {
                if (requestId && requestId !== AssetManagementLastRequest.getId()) return;
                storeAssetListResponse(response, storeName, stateList, params, shouldRefresh, rootModel);
            })
            .catch((error) => {
                setError(error);
            });
    };

    // Fetch a single asset from the API.
    const fetchAsset = async (assetId: string, refresh?: boolean, requestId?: string) => {
        setError(null);
        // See if we allready have fetched the asset before if refreshing it is not required.
        const foundAsset = refresh ? undefined : findAssetInStore(assetId, storeName);

        if (foundAsset) return foundAsset;

        // If a request with the same id is allready in progress, we do not want to make another request.
        // if (requestId && requestId === AssetManagementLastRequest.getId()) return null;

        // If a new request is made with a requestId, we store the request id to make sure we only handle the last request.
        AssetManagementLastRequest.setId(requestId);

        const result = await AssetManagementService.getAsset(assetId)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                setError(error);
                return null;
            });
        return result;
    };

    // Fetch collections from the API.
    const fetchCollections = async (refresh: boolean, payload?: FetchPayload, requestId?: string) => {
        setError(null);

        // If a request with the same id is allready in progress, we do not want to make another request.
        if (requestId && requestId === AssetManagementLastRequest.getId()) return;

        // If a new request is made with a requestId, we store the request id to make sure we only handle the last request.
        if (requestId) AssetManagementLastRequest.setId(requestId);

        const collectionsListState = getCollectionsListState(storeName);
        const shouldRefresh = Boolean(refresh || payload);

        if (shouldRefresh) ComponentStoreHelpers.setModel(storeName, 'collectionsList', null);

        // Do not wait for the API to set the choosen filters.
        if (payload?.filters) ComponentStoreHelpers.setModel(storeName, 'state.collectionFilters', payload.filters);

        const params = getListParams(type, collectionsListState, 'available', shouldRefresh, payload);

        await AssetManagementService.getCollectionBatch(params)
            .then((response) => {
                if (requestId && requestId !== AssetManagementLastRequest.getId()) return;
                const { collections, nextPageToken } = response;
                ComponentStoreHelpers.setMultiModels(storeName, [
                    ['collectionsList', shouldRefresh ? collections : [...(collectionsListState.stateList || []), ...collections]],
                    ['collectionsNextPageToken', nextPageToken],
                    ['state.collectionFilters', params.filters || {}],
                    ['state.collectionSortField', params.sortField],
                    ['state.collectionSortDirection', params.sortDirection]
                ]);
            })
            .catch((error) => {
                setError(error);
            });
    };

    // Fetch collection items from the API.
    const fetchCollectionItems = async (collectionId: string, refresh: boolean, payload?: FetchPayload, requestId?: string) => {
        setError(null);

        // If a request with the same id is allready in progress, we do not want to make another request.
        if (requestId && requestId === AssetManagementLastRequest.getId()) return;

        // If a new request is made with a requestId, we store the request id to make sure we only handle the last request.
        AssetManagementLastRequest.setId(requestId);

        const rootModel = `collectionsContent.${collectionId}.`;
        const listState = getListState(storeName, 'available', collectionId);
        const { stateList } = listState;
        const shouldRefresh = Boolean(refresh || payload);

        if (shouldRefresh) {
            resetList(storeName, rootModel);
        } else {
            ComponentStoreHelpers.setModel(storeName, `${rootModel}assetsLoading`, true);
        }

        // Do not wait for the API to set the choosen filters.
        if (payload?.filters) ComponentStoreHelpers.setModel(storeName, `${rootModel}assetFilters`, payload.filters);

        const params = getListParams(type, listState, 'available', shouldRefresh, payload);
        const transformedAssetsParams = transformAssetParamsForRootKeys(params);

        await AssetManagementService.getCollectionItems(collectionId, transformedAssetsParams, { isVerbose: fullDataInList })
            .then((response) => {
                if (requestId && requestId !== AssetManagementLastRequest.getId()) return;
                storeAssetListResponse(response, storeName, stateList, params, shouldRefresh, rootModel);
            })
            .catch((error) => {
                setError(error);
            });
    };

    // Get a new collections tree from the API, but also update fields and filters since they might have changed.
    const fetchCollectionsTree = async (collectionId?: string) => {
        setError(null);

        const { setAssetManagementConfig, ...currentConfig } = assetManagementConfig;
        setAssetManagementConfig({ ...currentConfig, collectionsTree: [] });

        const { setup, type } = currentConfig;
        const { assetFields, assetFilterOrder } = setup;

        await AssetManagementService.getCollectionsTree({ type })
            .then(async (response) => {
                const { structure: collectionsTree } = response;

                // Get the asset fields and filters with options.
                const subTypeItems = getSubTypeItems(assetManagementConfig);
                const assetFieldsWithOptions = await getAssetFieldsWithOptions(assetFields, collectionsTree, subTypeItems, type);
                const assetFilterSetup = getAssetFilterSetup(assetFieldsWithOptions, assetFilterOrder);
                setAssetManagementConfig({ ...currentConfig, collectionsTree, assetFields: assetFieldsWithOptions, assetFilterSetup });

                // If a collection id is provided, we also open the path to that collection in the collection selector.
                if (collectionId) {
                    const nestedCollectionIdsPath = getNestedCollectionPath(collectionsTree, collectionId).map((c) => c.id);
                    const newCollectionsOpen = [...new Set([...collectionsOpen, ...nestedCollectionIdsPath, COLLECTION_TREE_ROOT_ID])];
                    setCollectionsOpen(newCollectionsOpen);
                }
            })
            .catch((error) => {
                return setError(error);
            });
    };

    const refreshLists = (status: AssetStatus) => {
        fetchAssets(true, status);
        if (status === 'available') {
            const loadedCollections = ComponentStoreHelpers.getItem(storeName, 'collectionsContent');
            if (loadedCollections) {
                Object.keys(loadedCollections).forEach((collectionId) => {
                    resetList(storeName, `collectionsContent.${collectionId}.`);
                });
            }
            fetchCollections(true);
        }
    };

    return {
        error,
        fetchAssets,
        fetchAsset,
        fetchCollections,
        fetchCollectionItems,
        fetchCollectionsTree,
        refreshLists
    };
};

export default useAMFetch;
