import { CollectionTreeChild } from 'types/collection.type';
import { AssetFieldOption } from 'components/asset-management/types/asset-management-field.type';

// Build an options array from the collection tree
const getCollectionOptionsFromTree = (collectionsTree: CollectionTreeChild[]): AssetFieldOption[] => {
    const collectionList: AssetFieldOption[] = [];

    const buildNestedOptions = (collection: CollectionTreeChild, currentDepth: number) => {
        collectionList.push({ value: collection.id, label: collection.title, depth: currentDepth });
        if (collection.children?.length) {
            collection.children.forEach((child) => {
                buildNestedOptions(child, currentDepth + 1);
            });
        }
    };
    collectionsTree.forEach((collection) => {
        buildNestedOptions(collection, 0);
    });

    return collectionList;
};

export { getCollectionOptionsFromTree };
