import { AssetStatus } from 'types/asset.type';

// Get the root model in de Redux store for assets ith a certain status.
const getRootModelFromStatus = (status: AssetStatus): 'binned.' | 'draft.' | '' => {
    const rootModel = (() => {
        if (status === 'binned') {
            return 'binned.';
        } else if (status === 'draft') {
            return 'draft.';
        } else {
            return '';
        }
    })();

    return rootModel;
};
export { getRootModelFromStatus };
