import { AssetV2 } from 'types/asset.type';
import { getEditedCreativeAsset } from 'components/creative-management-v2/utilities';
import AssetManagementBaseService from './asset-management.base.service';
import { isCreativeAsset } from '../utilities';

export default class AssetManagementService extends AssetManagementBaseService {
    // Some asset types store their data outside Asset Management while being edited, If so get that data before sending them to the API
    static getExtendedAsset = (asset: AssetV2<unknown, unknown>): AssetV2<unknown, unknown> => {
        if (isCreativeAsset(asset)) {
            // If a creative is being edited, it stores it's data in the Campaign Editor, so we need to get it before sending it to the API.
            // In all other cases, we just use the asset as is.
            return getEditedCreativeAsset(asset);
        }
        return asset;
    };

    // When patching an asset, we want to send the right data with it.
    static async patchAsset(asset: AssetV2<unknown, unknown>, queryParams?: { updateOnlyMetadata?: boolean }): Promise<AssetV2<unknown, unknown>> {
        const extendedAsset = this.getExtendedAsset(asset);
        return await super.patchAsset(extendedAsset, queryParams);
    }
}
